@import "~@flaticon/flaticon-uicons/css/all/all";
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: white;
}

html {
    touch-action: pan-y;
}

body {
    background-color: transparent;
    height: 100vh;
    display: flex;
    justify-content: center;
}

body::-webkit-scrollbar{
    -webkit-appearance: none !important;
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background: transparent !important;
}

.Weather {
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 0;
}

.Weather i {
    vertical-align: middle;
}

.Weather #location {
    font-size: 4vh;
    word-wrap: break-word;
}

.Weather #highlow {
    font-size: 3vh;
}

.Weather #degrees {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Weather #temperature {
    margin-top: 1vh;
}

.Weather #temperature i {
    margin-right: 3vw;
}

.Weather #degrees.scrolledDown {
    flex-direction: row;
}

.Weather #highlow.scrolledDown {
    font-size: 2vh;
    margin-top: 1vh;
    padding-left: 3vw;
}

.Weather .boxes {
    width: 100vw;
    padding: 3vh 5vw 3vh 5vw;
}

.Weather .box {
    background-color: #45465c;
    border-radius: 8px;
}

.Search h1 {
    font-size: 4vh;
}

.Settings {
    width: 100vw;
    height: calc(88vh - env(safe-area-inset-top));
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Settings h1 {
    font-size: 4vh;
}

.Settings h4 {
    color: #acb8c1;
    margin: 0.5vh 2vw;
}

.Settings .Preferences, .Settings .APIkeys {
    width: 100vw;
    align-self: start;
    padding: 3vh 5vw 0vh 5vw;
}

.Settings .Miscellaneous {
    width: 100vw;
    align-self: start;
    margin-top: auto;
    margin-bottom: 3vh;
    padding: 3vh 5vw 0vh 5vw;
}

.Settings .PreferenceBox {
    background-color: #202329;
    margin: 1vh 2vw;
    border-radius: 8px;
}

.Settings .PreferenceItem {
    height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.Settings .PreferenceBox hr {
    height: 0px;
    border: none;
    border-top: 1px solid #687179;
    margin-left: 5vw;
}

.Settings .PreferenceItem label {
    position: absolute;
    pointer-events: none;
    margin-left: 3vw;
    font-size: 2vh;
}

.Settings .PreferenceItem label[for=button] {
    margin: 0;
    align-self: center;
}

.Settings .PreferenceItem select {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    appearance: none;
    background-color: #202329;
    border: none;
    color: transparent;
    outline: none;
    transition: background-color 0.1s ease-in-out;
}

.Settings .PreferenceItem select:active {
    background-color: #33363b;
}

.Settings .PreferenceItem input {
    width: 100%;
    height: 100%;
    padding-left: 45vw;
    padding-right: 5vw;
    border-radius: 8px;
    appearance: none;
    background-color: #202329;
    border: none;
    color: #acb8c1;
    font-size: 2vh;
    outline: none;
    text-align: right;
    transition: background-color 0.1s ease-in-out;
}

.Settings .PreferenceItem input:active {
    background-color: #33363b;
}

.Settings .PreferenceItem input:focus::placeholder {
    color: transparent;
}

.Settings .PreferenceItem span {
    pointer-events: none; 
    position: absolute;
    align-self: end;
    margin-right: 3vw;
    color: #acb8c1;
    font-size: 2vh;
}

.BottomBar {
    background: #3c3e57;
    border-radius: 15px 15px 0px 0px;
    width: 100vw;
    height: 12vh;
    position: fixed;
    bottom: 0vh;
    left: 0vw;
    padding-bottom: 1vh;
}

.BottomBar .items {
    display: flex;
    list-style: none;
    position: relative;
    height: 100%;
    align-items: center;
}

.BottomBar .items .item {
    text-align: center;
    flex: 1 1 0px;
    position: relative;
}

.BottomBar .items .item a {
    color: #acb8c1;
    text-decoration: none;
    font-size: 0.8em;
}

.BottomBar .items .item a.active,
.BottomBar .items .item a.active i {
    color: #ffffff;
}

.BottomBar .items .item i {
    color: #acb8c1;
    display: block;
    font-size: 2.5rem;
}